import classNames from "classnames";
import { Link } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { FacultyData } from "../pages/faculty";

interface FacultyItemProps {
  faculty: FacultyData;
  showLectures?: boolean;
  id: string;
  first: boolean;
}
const FacultyItem: React.FC<FacultyItemProps> = ({
  faculty,
  showLectures = false,
  id,
  first,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <div className={classNames(["text-left", !first && "mt-1"])} id={id}>
      <div
        className={classNames([
          "py-2 bg-zinc-800 rounded-b flex justify-between items-center px-5",
          showLectures && "cursor-pointer",
          !first && "rounded-t",
        ])}
        onClick={handleClick}
      >
        <div>
          <h4 className="text-xl text-red-500">{faculty.Name}</h4>
          <p>
            {faculty.Institution} | {faculty.Country}
          </p>
        </div>
        {showLectures && !!faculty.Programme && (
          <div className="px-3 py-2 bg-red-900 rounded flex">
            <p>
              <span className="hidden md:inline">Lectures: </span>
              {faculty.Programme.length}
            </p>
            {!open ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
      {showLectures && (
        <div className={classNames([!open ? "hidden" : "px-2"])}>
          {!!faculty.Programme &&
            faculty.Programme.map((lecture, index) => (
              <Link
                to={"/programme#" + lecture.recordId}
                key={lecture.recordId}
              >
                <div
                  className={classNames([
                    "py-2 px-3",
                    index !== 0 && "border-t border-zinc-600",
                  ])}
                >
                  <p>{lecture.data.Name}</p>
                  <p className="text-zinc-400">
                    {lecture.data.day}, {lecture.data.Start_Time} -{" "}
                    {lecture.data.End_Time}
                  </p>
                </div>
              </Link>
            ))}
        </div>
      )}
    </div>
  );
};

export default FacultyItem;
