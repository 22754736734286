import classNames from "classnames";
import { graphql } from "gatsby";
import * as React from "react";
import FacultyItem from "../components/FacultyItem";
import Layout from "../layout/Layout";

interface FacultyPageProps {
  data: {
    allAirtable: {
      group: {
        nodes: {
          data: FacultyData;
          recordId: string;
        }[];
        fieldValue: string;
        totalCount: number;
      }[];
    };
  };
}

export interface FacultyData {
  Name: string;
  Institution: string;
  Country: string;
  Programme?: {
    recordId: string;
    data: {
      Name: string;
      day: string;
      Start_Time: string;
      End_Time: string;
    };
  }[];
}

interface SortData {
  data: FacultyData;
}

const FacultyPage: React.FC<FacultyPageProps> = ({ data }) => {
  const sortByName = (a: SortData, b: SortData) => {
    const nameA = a.data.Name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.data.Name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };
  return (
    <Layout title="Faculty">
      {/* <h2 className="text-4xl text-center font-light uppercase">Faculty</h2> */}
      <div className="mt-3 text-center">
        {data.allAirtable.group
          .filter((g) => g.fieldValue == "Faculty")
          .map((facultyType) => (
            <div key={facultyType.fieldValue}>
              <div className="bg-red-900 py-3 mt-5 rounded-t border-b border-zinc-500">
                <h3 className="text-2xl">{facultyType.fieldValue}</h3>
              </div>
              {facultyType.nodes.sort(sortByName).map((faculty, index) => (
                <FacultyItem
                  key={faculty.recordId}
                  id={faculty.recordId}
                  faculty={faculty.data}
                  first={index === 0}
                  showLectures
                />
              ))}
            </div>
          ))}
        {data.allAirtable.group
          .filter((g) => g.fieldValue == "Board")
          .map((facultyType) => (
            <div key={facultyType.fieldValue}>
              <div className="bg-red-900 py-3 mt-5 rounded-t border-b border-zinc-500">
                <h3 className="text-2xl">{facultyType.fieldValue}</h3>
              </div>
              {facultyType.nodes.map((faculty, index) => (
                <FacultyItem
                  key={faculty.recordId}
                  id={faculty.recordId}
                  faculty={faculty.data}
                  first={index === 0}
                />
              ))}
            </div>
          ))}
        {data.allAirtable.group
          .filter((g) => g.fieldValue !== "Board" && g.fieldValue !== "Faculty")
          .map((facultyType) => (
            <div key={facultyType.fieldValue}>
              <div className="bg-red-900 py-3 mt-5 rounded-t border-b border-zinc-500">
                <h3 className="text-2xl">{facultyType.fieldValue}</h3>
              </div>
              {facultyType.nodes.map((faculty, index) => (
                <FacultyItem
                  key={faculty.recordId}
                  id={faculty.recordId}
                  faculty={faculty.data}
                  first={index === 0}
                />
              ))}
            </div>
          ))}
      </div>
    </Layout>
  );
};

export default FacultyPage;

export const facultyPageQuery = graphql`
  query FacultyPageQuery {
    allAirtable(
      filter: { table: { eq: "Faculty" } }
      sort: { fields: [data___sorting, data___Name] }
    ) {
      group(field: data___Faculty_type) {
        nodes {
          data {
            Name
            Institution
            Country
            Programme {
              recordId
              data {
                Name
                day: Start_Time(formatString: "MMM D")
                Start_Time(formatString: "HH:mm")
                End_Time(formatString: "HH:mm")
              }
            }
          }
          recordId
        }
        fieldValue
        totalCount
      }
    }
  }
`;
